<template>
  <section
    class="add-team overflow-y-scroll w-full z-10"
    @click="clickOnBackground"
  >
    <div class="popup-container flex items-center w-full">
      <div class="popup w-full" @click.prevent.stop>
        <slot
          class="heartbeat overflow-y-scroll popup-element"
          @click.prevent.stop
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "popup-base",
  props: {},
  data() {
    return {};
  },
  methods: {
    clickOnBackground() {
      // this.$emit("clickOnBackground");
      // console.log("hi");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/sass/variables";
.add-team {
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  position: fixed;
  overflow-y: hidden;
  justify-content: center;
  z-index: 9999;
}
.popup-container {
  position: relative;
  height: 100%;
  overflow: scroll !important;
  margin: auto;
}
.popup {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.popup-element {
  height: 100%;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-1-27 9:53:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
.popup {
  -webkit-animation: popup-animation 0.3s linear; /* Safari 4+ */
  -moz-animation: popup-animation 0.3s linear; /* Fx 5+ */
  -o-animation: popup-animation 0.3s linear; /* Opera 12+ */
  animation: popup-animation 0.3s linear; /* IE 10+, Fx 29+ */
}
@-webkit-keyframes popup-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes popup-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes popup-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popup-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .popup {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 767px) {
  .popup {
    width: 100%;
  }
}
</style>
