<template>
  <div
    class="inputo p-1 text-c14"
    :class="{
      error: error,
      success: success === true,
      deseable: deseable === true,
    }"
    :style="{
      height: height,
      border: border,
      'border-radius': radius,
      'background-color': background,
    }"
  >
    <div v-if="!deseable" class="flex h-full items-center">
      <icon
        v-if="icon !== null"
        :data="icon"
        :height="iconHeight"
        :width="iconHeight"
        class="icone cursor-pointer ml-2"
        original
      />
      <input
        v-if="type === null"
        v-model="value"
        type="text"
        class="w-full h-full"
        :placeholder="placeholder"
        :style="{
          'font-size': size,
          'font-weight': weight,
          color: color,
          padding: padding,
          'border-radius': radius,
        }"
      />
      <input
        v-if="type !== null && type !== 'email'"
        v-model="value"
        :maxlength="maxLength"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{
          'font-size': size,
          'font-weight': weight,
          color: color,
          padding: padding,
          'border-radius': radius,
        }"
        @keypress="isNumberOnly"
      />

      <input
        v-if="type === 'email'"
        v-model="value"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{
          'font-size': size,
          'font-weight': weight,
          color: color,
          padding: padding,
          'border-radius': radius,
        }"
      />
      <icon
        v-if="iconend !== null"
        :data="iconend"
        :height="iconHeight"
        :width="iconHeight"
        class="icone cursor-pointer ml-2 mr-5"
        original
      />
      <div v-if="label !== null" class="md:w-1/6 w-2/5 flex justify-end">
        <div
          class="lab mr-4 p-2 pl-4 pr-4 flex text-90 items-center justify-center w-auto"
        >
          <div>{{ label }}</div>
        </div>
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  name: "input-base",
  components: {},

  props: {
    deseable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: "0px 7px 0px 7px",
    },
    icon: {
      type: Object,
      default: null,
    },
    iconend: {
      type: Object,
      default: null,
    },
    valueT: {
      type: [String, Number],
      default: "",
    },
    size: {
      type: String,
      default: "14px",
    },
    weight: {
      type: String,
      default: "400",
    },
    height: {
      type: String,
      default: "40px",
    },
    border: {
      type: String,
      default: "1px solid rgba(93, 56, 224, 0.3)",
    },
    radius: {
      type: String,
      default: "4px",
    },
    background: {
      type: String,
      default: "white",
    },
    color: {
      type: String,
      default: "#0e0e0e",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    iconHeight: {
      type: String,
      default: "25",
    },
    name: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      error: null,
      success: false,
      value: "",
      temoin: "",
    };
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.value === "" && this.type && this.type !== "email") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = 0;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.temoin = 0;
      }

      if (this.temoin !== this.value) {
        if (this.type === "email") {
          // console.log(this.emailValidation(this.value))
          if (this.value.length > 0) {
            if (this.emailValidation(this.value) === true) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.success = true;
              this.$emit("info", this.value);
              this.$emit("oga", this.value, this.name);
            } else {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.error = "email";
            }
          }
          if (this.isRequired && this.value.length === 0) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.error = "email";
          }
        } else {
          if (
            this.isRequired === true &&
            this.value.length === 0 &&
            this.value.length !== ""
          ) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.error = "email";
          }
          if (
            this.isRequired === true &&
            this.value.length > 0 &&
            this.value.length !== ""
          ) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.success = true;
          }

          if (this.type) {
            if (this.type.toUpperCase() === "NUMBER") {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.value = parseInt(this.value);
            }
          }

          if (!this.name) {
            this.$emit("info", this.value);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.temoin = this.value;
          } else {
            this.$emit("oga", this.value, this.name);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.temoin = this.value;
          }
        }
      }
    },
  },

  created() {
    this.value = this.valueT;
    this.temoin = this.valueT;
  },

  methods: {
    isNumberOnly($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    emailValidation(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables.scss";
.inputo {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  font-family: $font-default;
}
input {
  background-color: transparent;
}
.inputo:focus-within {
  border: 1px solid $hover-color !important;
}
.inputo:hover {
  border: 1px solid $hover-color !important;
}
.error {
  border: 1px solid #ea9f9f !important;
}
.success {
  border: 1px solid rgba(93, 56, 224, 0.3) !important;
}
.lab {
  background: #f2f2f2;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  line-height: 27px;
}
.deseable {
  background: #eeeeee;
}

@media screen and (max-width: 769px) and (min-width: 701px) {
  .inputo {
    height: 65px;
  }
  input {
    font-size: 18px;
  }
}

@media screen and (max-width: 700px) {
  .inputo {
    height: 55px;
  }
  input {
    font-size: 14px;
  }
}
</style>
