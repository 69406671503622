<template>
  <div>
    <div
      class="h-60 flex justify-center items-center cursor-pointer bout"
      :style="{
        height: height,
        'font-style': styles,
        padding: padding,
        'border-radius': radius,
        border: border,
        'background-color': background,
        color: color,
        'font-weight': weight,
        'font-size': size,
      }"
      @click="continuer"
    >
      <icon
        v-if="icon !== null && iconColor"
        :data="icon"
        :height="iconHeight"
        :width="iconHeight"
        class="mr-2"
        :color="iconColor"
        original
      />
      <icon
        v-if="icon !== null && !iconColor"
        :data="icon"
        :height="iconHeight"
        :width="iconHeight"
        class="mr-2"
        original
      />
      <div v-if="!charge">
        {{ label }}
      </div>
      <easy-spinner v-if="charge" type="dots" size="30" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CedricButton",
  props: {
    height: {
      type: String,
      default: "50px",
    },
    weight: {
      type: String,
      default: "400",
    },
    iconHeight: {
      type: String,
      default: "20",
    },
    styles: {
      type: String,
      default: "normal",
    },
    icon: {
      type: Object,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    radius: {
      type: String,
      default: "10px",
    },
    border: {
      type: String,
      default: "none",
    },
    background: {
      type: String,
      default: "#2C5AA0",
    },
    color: {
      type: String,
      default: "#fff",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "18px",
    },
    charge: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "1px 1px 1px 1px",
    },
  },

  methods: {
    continuer() {
      if (this.charge === false) {
        this.$emit("info", this.label);
        this.$emit("oga", true);
      } else {
        this.$toast.warning("Veuillez patienter");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.bout:hover {
  //border: 1px solid gray !important;
  box-shadow: 0px 0px 7px 0px #00000040;
}
.svg-icon {
  fill: transparent;
}
.bout {
  font-family: $font-family;
}
</style>
