/**
 *PROJECT start-samadou-template
 * Created by WebStorm.
 * Author Samadou SALAHOU<samadousalahou@gmail.com>)
 * Date 14/08/2022
 * TIME 12:33
 **/
import axios from "axios";
import apiRouter from "../router/api-routes";

const http = axios.create({
  baseURL: apiRouter.bitnobBaseURL,
});
http.defaults.headers.common["x-api-key"] =
  process.env.VUE_APP_BITNOB_API_KEY;
http.defaults.headers.common.Authorization =
  "Bearer " + process.env.VUE_APP_BITNOB_API_KEY;
http.interceptors.request.use(
  function (request) {
    if (!request.baseURL.indexOf(apiRouter.baseURL)) {
      console.log("owner site");
    } else {
      console.log("other site");
    }
    return request;
  },
  function (error) {
    return error;
  }
);

http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(
      error.response || {
        response: { status: error.statusCode, message: error.message },
      }
    );
  }
);

export default http;
