<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div class="success-container p-8">
          <div class="flex justify-between items-center">
            <div class="text-2xl font-bold font-serif"></div>

            <div
              class="w-6 h-6 flex items-center justify-center rounded-c5"
              @click="fermer"
            >
              <img :src="images.close" alt="close" original />
              <!-- <icon
                :data="icons.fermer"
                height="15"
                width="15"
                class="cursor-pointer"
                original
              /> -->
            </div>
          </div>
          <div class="flex items-center justify-center mb-6">
            <img :src="icons.creditcard" height="60" width="60" original />
          </div>
          <div class="text-2xl text-center font-bold font-serif mb-3">
            {{ hOne }}
          </div>
          <div class="text-center text-base font-normal description">
            {{ description }}
          </div>

          <div class="mt-12 mb-4 flex justify-center">
            <div class="w-1/4">
              <buton
                background="#fff"
                color="#CDD1D6"
                :label="boutonLabell"
                :width="boutonWidthh"
                height="40px"
                size="14px"
                border="1px solid #CDD1D6"
                radius="4px"
                @click="fermer"
              />
            </div>

            <div class="w-1/2 ml-6" v-if="boutonType">
              <buton
                size="14px"
                :background="background"
                :width="boutonWidth"
                :label="boutonLabel"
                :charge="charge"
                radius="4px"
                @click="continuer"
              />
            </div>

            <div class="w-1/4 ml-6" v-if="!boutonType">
              <buton
                size="14px"
                height="40px"
                :background="background"
                :width="boutonWidth"
                :label="boutonLabel"
                :charge="charge"
                radius="4px"
                @click="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from "../helper/add/popup-base";
import buton from "../helper/add/button";
import fermer from "@/assets/icons/fermer.svg";
import creditcard from "@/assets/icons/ic_creditcard.svg";
import close from "@/assets/images/img_close.webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Success",
  components: { PopupBase, buton },
  props: {
    charge: {
      type: Boolean,
      default: false,
    },
    activation: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    description: {
      type: String,
      default: null,
    },
    hOne: {
      type: String,
      default: "Paiement",
    },
    ico: {
      type: String,
      // default: icons.creditcard,
    },
    boutonType: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: "#FF914D",
    },
    boutonLabel: {
      type: String,
      default: "Passer au paiement",
    },
    boutonLabell: {
      type: String,
      default: "Effectuer plus tard",
    },
    boutonWidth: {
      type: String,
      default: "40px",
    },
    boutonWidthh: {
      type: String,
      default: "28px",
    },
  },
  data() {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: { fermer, creditcard },
      images: {
        close,
      },
    };
  },

  created() {
    this.wantToMakeOffer = this.activation;
  },

  methods: {
    fermer() {
      this.$emit("fermer", false);
    },
    continuer() {
      this.$emit("continuer", true);
    },
    logState(answer) {
      this.wantToMakeOffer = answer;
      this.$emit("oga", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/mixin";
.success {
  z-index: 999;
}

.cardinal {
  width: 67%;
}

.description {
  font-family: $font-family;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: $white;
  height: auto;
  margin: auto;
  border-radius: 12px;
}
@media screen and (max-width: 700px) {
  .success-container {
    width: 100%;
    height: 50vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px) {
  .cardinal {
    width: 65%;
  }
  .titre {
    font-size: 18px;
  }
  .button {
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px) {
  .cardinal {
    width: 90%;
  }
  .titre {
    font-size: 18px;
  }
  .button {
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px) {
  .cardinal {
    width: 100%;
  }
  .titre {
    font-size: 14px;
  }
  .button {
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
